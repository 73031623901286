import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Img from 'gatsby-image'
import Button from '../../components/Button'
import Typography from '@material-ui/core/Typography'

import { renderRichText } from 'gatsby-source-contentful/rich-text'
import options from '../../templates/richTextRenderers'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'

import CircleTimer from '../../components/CircleTimer'

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow)

const useStyles = (props) =>
    makeStyles((theme) => ({
        table: {
            // minWidth: 700,
        },
        media: {
            height: 0,
            paddingTop: '100%', // 16:9
        },
        card: {
            position: 'relative',
        },
        overlay: {
            position: 'absolute',
            top: '20%',
            left: '20px',
            right: '20px',
            padding: '10px',
            color: 'white',
            backgroundColor: 'rgba(134, 134, 134, 0.5)',
        },
        buttonPrimary: {
            width: '100%',
            height: '4.5rem',
            borderRadius: '0.938rem',
            color: theme.palette.text.white,
            fontSize: '1.25rem',
            padding: '0px 20px',
            background:
                'linear-gradient(138.25deg, #FFAF75 7%, #F45F00 95.28%)',
            '&:hover': {
                background:
                    'linear-gradient(138.25deg, rgba(255, 175, 117, 0.5) 7%, rgba(244, 95, 0, 0.5) 95.28%)',
            },
            [theme.breakpoints.down('sm')]: {
                width: '85%',
                height: '2.5rem',
                fontSize: '0.875rem',
                lineHeight: '1.125rem',
            },
        },
    }))

export default (props) => {
    const data = props.data[props.componentId]

    const classes = useStyles({ ...props, data })()

    const hasTextMessage = Boolean(data.text)

    return (
        <>
            <Box py={6}>
                <Container maxWidth="lg">
                    <Box mb={3}>
                        <Grid container direction="row-reverse">
                            <Grid container item md={7}>
                                <Grid item md={1}>
                                    <Box mt={3} pr={2}>
                                        <Img fluid={data.courseIcon.fluid} />
                                    </Box>
                                </Grid>
                                <Grid item lg={11}>
                                    <h1>{data.courseTitle}</h1>
                                    <h3>Starts in:</h3>
                                    <CircleTimer
                                        dateAndTime={data.dateAndTime}
                                    />
                                </Grid>
                                <Grid
                                    container
                                    justifyContent="flex-end"
                                    alignItems="center"
                                >
                                    <Box mb={3}>
                                        <Button
                                            className={classes.buttonPrimary}
                                            target="blank"
                                            disableElevation
                                            to="/forms/sf/student/apply/"
                                        >
                                            Enrol now
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item md={1}></Grid>
                            <Grid item md={4} xs={12}>
                                <Card className={classes.card}>
                                    <CardMedia
                                        image={data.image.fluid.src}
                                        className={classes.media}
                                    />
                                    <div className={classes.overlay}>
                                        {hasTextMessage && (
                                            <Typography component="span">
                                                {renderRichText(
                                                    data.text,
                                                    options()
                                                )}
                                            </Typography>
                                        )}
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table
                            className={classes.table}
                            aria-label="customized table"
                        >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="left">
                                        Subject
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        Course
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        Start date & time
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        End date & time
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        Live Sessions (BST)
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.courses.map((row) => {
                                    const hasEndDate = Boolean(row.endDate)
                                    const hasDuration = Boolean(row.duration)

                                    return (
                                        <StyledTableRow key={row.subjectName}>
                                            <StyledTableCell align="left">
                                                {row.subjectName}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {row.course}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {row.startDateAndTime}
                                            </StyledTableCell>
                                            {hasEndDate && (
                                                <StyledTableCell align="left">
                                                    {row.endDate}
                                                </StyledTableCell>
                                            )}
                                            {hasDuration && (
                                                <StyledTableCell align="left">
                                                    {row.duration}
                                                </StyledTableCell>
                                            )}
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            </Box>
        </>
    )
}
