import React from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
    App: {
        display: 'flex',
        justifyContent: 'space-around',
        textAlign: 'center',
        paddingTop: '40px',
    },
}))

const minuteSeconds = 60
const hourSeconds = 3600
const daySeconds = 86400

const timerProps = {
    isPlaying: true,
    size: 100,
    strokeWidth: 6,
}

const renderTime = (dimension, time) => {
    return (
        <div className="time-wrapper">
            <div className="time">{time}</div>
            <div>{dimension}</div>
        </div>
    )
}

const getTimeSeconds = (time) => (minuteSeconds - time) | 0
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0
const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0
const getTimeDays = (time) => (time / daySeconds) | 0

const CircleTimer = (props) => {
    const stratTime = Date.now() / 1000 // use UNIX timestamp in seconds
    //const endTime = 1628510400 //use UNIX timestamp in seconds
    const endTime = new Date(props.dateAndTime).getTime() / 1000

    const remainingTime = endTime - stratTime
    const days = Math.ceil(remainingTime / daySeconds)
    const daysDuration = days * daySeconds

    const classes = useStyles()

    return (
        <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-around"
            p={2}
            m={2}
        >
            <Box mb={2}>
                <CountdownCircleTimer
                    {...timerProps}
                    colors={[['#7E2E84']]}
                    duration={daysDuration}
                    initialRemainingTime={remainingTime}
                >
                    {({ elapsedTime }) =>
                        renderTime(
                            'days',
                            getTimeDays(daysDuration - elapsedTime)
                        )
                    }
                </CountdownCircleTimer>
            </Box>
            <Box>
                <CountdownCircleTimer
                    {...timerProps}
                    colors={[['#D14081']]}
                    duration={daySeconds}
                    initialRemainingTime={remainingTime % daySeconds}
                    onComplete={(totalElapsedTime) => [
                        remainingTime - totalElapsedTime > hourSeconds,
                    ]}
                >
                    {({ elapsedTime }) =>
                        renderTime(
                            'hours',
                            getTimeHours(daySeconds - elapsedTime)
                        )
                    }
                </CountdownCircleTimer>
            </Box>
            <Box>
                <CountdownCircleTimer
                    {...timerProps}
                    colors={[['#EF798A']]}
                    duration={hourSeconds}
                    initialRemainingTime={remainingTime % hourSeconds}
                    onComplete={(totalElapsedTime) => [
                        remainingTime - totalElapsedTime > minuteSeconds,
                    ]}
                >
                    {({ elapsedTime }) =>
                        renderTime(
                            'minutes',
                            getTimeMinutes(hourSeconds - elapsedTime)
                        )
                    }
                </CountdownCircleTimer>
            </Box>
            <Box>
                <CountdownCircleTimer
                    {...timerProps}
                    colors={[['#218380']]}
                    duration={minuteSeconds}
                    initialRemainingTime={remainingTime % minuteSeconds}
                    onComplete={(totalElapsedTime) => [
                        remainingTime - totalElapsedTime > 0,
                    ]}
                >
                    {({ elapsedTime }) =>
                        renderTime('seconds', getTimeSeconds(elapsedTime))
                    }
                </CountdownCircleTimer>
            </Box>
        </Box>
    )
}

export default CircleTimer
